<template>
  <div>
    <b-card class="form-data min-h-62 mobile-transparent">
      <b-form v-if="show" @submit.prevent="handleSubmit">
        <div class="d-md-flex">
          <section class="form-service col-md-8 p-0">
            <b-form-group label="Escopo (nome):">
              <b-form-input
                :class="{ 'is-invalid': $v.form.scope.$error }"
                required
                placeholder="Adicione um escopo legal"
                v-model="$v.form.scope.$model"
              />
            </b-form-group>
            <b-form-group label="CNAE's de atuação:">
              <div>
                <multiselect
                  v-model="$v.form.services.$model"
                  @search-change="handleSearch"
                  :options="serviceOptions"
                  :multiple="true"
                  :group-select="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  placeholder="Selecione um serviço"
                  track-by="activity"
                  label="activity"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      class="multiselect__single"
                      v-if="values.length && !isOpen"
                    >
                      {{ values.map(({ activity }) => activity).join("; ") }}
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <span class="option__title">
                      {{ props.option.code }} - {{ props.option.activity }}
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">{{
                        props.option.activity
                      }}</span>
                      <span class="option__small">{{ props.option.desc }}</span>
                    </div>
                  </template>
                </multiselect>
              </div>
              <b-form-invalid-feedback v-if="!$v.form.services.$error">
                Campo obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
            <hr />
            <!-- Schedule -->
            <b-row>
              <b-col md="3">
                <b-form-group label="Abertura do contrato:">
                  <b-form-datepicker
                    label-today-button="Selecionar hoje"
                    today-button
                    locale="pt-BR"
                    hide-header
                    :show-decade-nav="false"
                    label-help=""
                    :min="new Date()"
                    placeholder="00/00/0000"
                    :date-format-options="dateFormat"
                    required
                    :class="{ 'is-invalid': $v.form.date_open.$error }"
                    v-model="$v.form.date_open.$model"
                    class="mb-2"
                  ></b-form-datepicker>
                  <b-form-invalid-feedback v-if="$v.form.date_open.$error">
                    Campo obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Inicio do contrato:">
                  <b-form-datepicker
                    :min="new Date($v.form.date_open.$model)"
                    locale="pt-BR"
                    hide-header
                    :show-decade-nav="false"
                    label-help=""
                    placeholder="00/00/0000"
                    required
                    :disabled="!$v.form.date_open.$model"
                    :date-format-options="dateFormat"
                    :class="{
                      'is-invalid':
                        $v.form.date_init.$error ||
                        !$v.form.date_init.rangeOpen,
                    }"
                    v-model="$v.form.date_init.$model"
                    class="mb-2"
                  ></b-form-datepicker>
                  <b-form-invalid-feedback
                    v-if="
                      $v.form.date_init.required && $v.form.date_init.rangeOpen
                    "
                  >
                    Campo obrigatório
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback v-if="!$v.form.date_init.rangeOpen">
                    A data deve ser maior do que a data de abertura
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Fim do contrato:">
                  <b-form-datepicker
                    placeholder="00/00/0000"
                    locale="pt-BR"
                    hide-header
                    :show-decade-nav="false"
                    label-help=""
                    @input="handleChangeValue()"
                    :date-format-options="dateFormat"
                    required
                    :class="{
                      'is-invalid':
                        $v.form.date_end.$error && !$v.form.date_end.rangeInit,
                    }"
                    v-model="$v.form.date_end.$model"
                    class="mb-2"
                  ></b-form-datepicker>
                  <b-form-invalid-feedback
                    v-if="
                      $v.form.date_end.required && $v.form.date_end.rangeInit
                    "
                  >
                    Campo obrigatório
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback v-if="!$v.form.date_end.rangeInit">
                    A data deve ser maior do que a data de início
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group label="Vigência do contrato:">
                  <span class="form-control input-total-value">
                    {{ getMonthDiff(form.date_init, form.date_end) }}
                    {{
                      getMonthDiff(form.date_init, form.date_end) === 1
                        ? "mês"
                        : "meses"
                    }}
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <hr />
            <!-- Money -->
            <b-row>
              <b-col md="3">
                <b-form-group label="Valor mensal do contrato:">
                  <b-form-input
                    required
                    @blur="handleChangeValue()"
                    v-money.lazy="maskMoney"
                    v-model="form.contract_value_mensal"
                    :class="{
                      'is-invalid': $v.form.contract_value_mensal.$error,
                    }"
                    placeholder="R$ 00,00"
                  />
                  <p
                    class="text-danger"
                    v-if="$v.form.contract_value_mensal.$error"
                  >
                    Não é um valor válido
                  </p>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Limite de dependência:"
                  :description="
                    !isMaxPercent
                      ? 'Percentual depêndecia que esse contrato representa no faturamento mensal'
                      : ''
                  "
                >
                  <masked-input
                    type="text"
                    name="percent"
                    class="form-control"
                    :class="{
                      'is-invalid': $v.form.dependency_limit_perc.$error,
                    }"
                    v-model="form.dependency_limit_perc"
                    :mask="maskPercent"
                    :guide="false"
                    :max="100"
                    @change.native="updateValue"
                    @input.native="updateValue"
                  >
                  </masked-input>
                  <b-form-invalid-feedback
                    v-if="$v.form.dependency_limit_perc.$error"
                  >
                    Campo obrigatório
                  </b-form-invalid-feedback>
                  <small class="text-danger" v-if="isMaxPercent">
                    É recomendado que o limite de dependência não ultrapasse 15%
                  </small>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Faturamento mensal:"
                  description="Faturamento mensal exigido do prestador"
                >
                  <span class="form-control input-total-value">
                    {{ form.monthly_invoicing_above || 0 | money }}
                  </span>
                  <div
                    class="text-danger"
                    v-if="$v.form.monthly_invoicing_above.$error"
                  >
                    Campo obrigatório
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Valor total do contrato:">
                  <span class="form-control input-total-value">
                    {{ form.contract_value_total | money }}
                  </span>
                  <div
                    class="text-danger"
                    v-if="$v.form.contract_value_total.$error"
                  >
                    Campo obrigatório
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </section>
          <div class="base-location col-md-4 pl-md-4 p-0">
            <b-form-group label="Distância da base">
              <b-input-group append="km" class="mb-2">
                <masked-input
                  type="text"
                  name="distance"
                  class="form-control"
                  :class="{
                    'is-invalid':
                      $v.form.operational_base_localization_distance.$error,
                  }"
                  v-model="form.operational_base_localization_distance"
                  :mask="maskDistance"
                  @change.native="updateValueDistance"
                  @input.native="updateValueDistance"
                >
                </masked-input>
              </b-input-group>
            </b-form-group>
            <b-card no-body class="distance-map">
              <section class="content">
                <gmap-map
                  :center="center"
                  :zoom="14"
                  class="map-provider w-100 h-100 position-absolute"
                  :options="{
                    strokeColor: '#347cff',
                    zoomControl: false,
                    scaleControl: false,
                    fullscreenControl: false,
                    mapTypeControl: false,
                    streetViewControl: false,
                    disableDoubleClickZoom: true,
                    draggable: false,
                    scrollwheel: false,
                    panControl: false,
                  }"
                />
                <b-badge variant="primary">
                  Raio de abrangência
                  {{ form.operational_base_localization_distance | number }}
                </b-badge>
                <div class="radius">
                  <div class="pin" />
                  <div
                    class="stroke"
                    :style="`height: ${getRadius()}px;width: ${getRadius()}px`"
                  />
                </div>
              </section>
            </b-card>
          </div>
        </div>
      </b-form>
    </b-card>
    <div class="bottom-navigation-service">
      <b-button variant="light" to="/service/list" class="w-md-0 w-sm-100">
        <span>Cancelar</span>
      </b-button>
      <div class="d-md-flex align-items-center action-save">
        <b-button
          variant="outline-primary"
          class="mr-4"
          :disabled="loadingSubmit"
          @click="handleSubmit(), (goToNext = false)"
        >
          <b-spinner v-if="loadingSubmit" small class="mr-2" />
          <span>Concluir edição</span>
        </b-button>
        <b-button
          variant="primary"
          :disabled="loadingSubmit"
          @click="handleSubmit(), (goToNext = true)"
        >
          <b-spinner v-if="loadingSubmit" small class="mr-2" />
          <span>Avançar para premissas</span>
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { api } from "@/services/api";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { isValidEmail } from "@/utils/validations/email";
import MaskedInput from "vue-text-mask";
import _ from "lodash";
import { gmapApi } from "vue2-google-maps";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const maskPercent = createNumberMask({
  prefix: "",
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 100,
  allowNegative: false,
  suffix: "%",
  precision: 0,
  masked: true,
});

const maskDistance = createNumberMask({
  prefix: "",
  includeThousandsSeparator: true,
  allowDecimal: false,
  integerLimit: 100,
  allowNegative: false,
  stringValue: true,
  suffix: "",
  spaceAfterSign: true,
  showPlusSign: true,
  precision: 0,
  masked: true,
});

export default {
  mixins: [validationMixin],
  components: {
    MaskedInput,
  },
  data() {
    return {
      goToNext: false,
      maskPercent,
      maskDistance: createNumberMask({ suffix: "", prefix: "" }),
      search: null,
      emailTextError: "Formato de e-mail inválido",
      emailsHasRegistred: [],
      errorEmail: false,
      isMaxPercent: false,
      center: {
        lat: -19.919122,
        lng: -43.938661,
      },
      maskMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 0,
      },
      dateFormat: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      homologationId: null,
      serviceId: null,
      show: true,
      loadingSubmit: false,
      serviceOptions: [],
      scopeOptions: [],
      emails: [],
      form: {
        emails: [],
        homologation: null,
        services: null,
        scope: null,
        contract_value_mensal: null,
        contract_value_total: null,
        dependency_limit_perc: null,
        monthly_invoicing_above: null,
        date_init: this.formatDate(),
        date_end: null,
        date_open: this.formatDate(),
        contract_months: null,
        operational_base_localization_distance: null,
      },
    };
  },
  validations: {
    form: {
      services: {
        required,
      },
      scope: {
        required,
      },
      contract_value_mensal: {
        required,
      },
      contract_value_total: {
        required,
      },
      dependency_limit_perc: {
        required,
      },
      monthly_invoicing_above: {
        required,
      },
      operational_base_localization_distance: {
        required,
      },
      date_open: {
        required,
      },
      date_init: {
        required,
        rangeOpen(val, { date_open }) {
          return new Date(val) >= new Date(date_open);
        },
      },
      date_end: {
        required,
        rangeInit(val, { date_init }) {
          return new Date(val) >= new Date(date_init);
        },
      },
    },
  },
  methods: {
    ...mapActions(["get_service_scope", "show_toast"]),
    handlerIsValidEmail(email) {
      return isValidEmail(email);
    },
    formatDate() {
      return this.moment(new Date()).format("YYYY-MM-DD");
    },
    getRadius() {
      const radius = this.$v.form.operational_base_localization_distance.$model;
      if (radius > 350) {
        return 350;
      }
      return radius / 100;
    },

    updateValueDistance(event) {
      const value = event.target.value.replaceAll(",", ".");

      this.form.operational_base_localization_distance = value;
      this.$forceUpdate();
    },

    updateValue(event) {
      const value = Number(event.target.value.replace("%", ""));
      if (value >= 100) {
        this.form.dependency_limit_perc = "100%";
      }
      this.$forceUpdate();
    },

    afterSubmit() {
      if (this.goToNext) {
        this.$router.push(
          `/homologation/${this.homologationId}/service/${
            this.serviceId
              ? `${this.serviceId}/edit/premises${
                  !this.user.is_admin ? "" : "-analysis"
                }/`
              : ""
          }`
        );
        return;
      }
      this.$router.push(`/homologation/${this.homologationId}/service/list`);
    },
    handleSubmit() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.loadingSubmit = true;
        let path = "/service-scope/";
        let funcApi = api.post;
        if (this.serviceId) {
          path = `/service-scope/${this.serviceId}/?user_id=${this.user.id}&homologation=${this.homologationId}`;
          funcApi = api.patch;
        }
        funcApi(path, this.beforeSendRequest())
          .then((response) => {
            this.loadingSubmit = false;
            let type = "";
            let message = "";
            if (!this.serviceId) {
              this.serviceId = response.data.id;
            }
            if (response.status === 400) {
              this.getEmailsErrros(response);
              return;
            }
            if (response.status === 201) {
              type = "success";
              message = "Serviço Cadastrado";
              this.afterSubmit();
            } else if (response.status === 200) {
              type = "success";
              message = "Serviço Editado.";
              this.afterSubmit();
            } else {
              type = "error";
              message = response.statusText;
            }
            this.show_toast({
              message,
              type,
            });
          })
          .catch(() => {
            this.loadingSubmit = false;
          });
      }
    },

    getEmailsErrros({ data }) {
      const tags = [...document.querySelectorAll(".b-form-tag")];
      tags.map((tag) => (tag.style = ""));
      data.errors.map((error) => {
        const errorType = error.field.split("[");
        if (errorType[0] === "emails") {
          const index = Number(errorType[1].replace("]", ""));
          document.querySelector(
            `[title='${this.emails[index]}']`
          ).style.background = "#dc3545";
          this.emailsHasRegistred.push(this.emails[index]);
        }
      });
    },

    handleChangeValue() {
      this.form.contract_value_total =
        this.parseInt(this.form.contract_value_mensal) *
        this.getMonthDiff(this.form.date_init, this.form.date_end);
    },

    beforeSendRequest() {
      const contract_value_total =
        this.parseInt(this.form.contract_value_mensal) *
        this.getMonthDiff(this.form.date_init, this.form.date_end);
      const form = {
        ...this.form,
        form: this.form.form?.id,
        template: this.form.template?.id,
        emails: this.emails.filter((email) => email),
        homologation: this.homologationId,
        services: this.form.services.map(({ id }) => id),
        contract_months: parseInt(
          this.getMonthDiff(this.form.date_init, this.form.date_end)
        ),
        contract_value_mensal: this.parseInt(this.form.contract_value_mensal),

        operational_base_localization_distance: +this.form.operational_base_localization_distance
          .replaceAll(" km", "")
          .replaceAll(".", "")
          .replaceAll(",", ""),

        contract_value_total,
        dependency_limit_perc: !isNaN(this.form.dependency_limit_perc)
          ? this.form.dependency_limit_perc
          : Number(this.form.dependency_limit_perc.replace("%", "")),
      };
      return form;
    },

    parseInt(value) {
      if (isNaN(value)) {
        return Number(
          value
            .replaceAll(".", "")
            .replace(",", ".")
            .replace("R$ ", "")
        );
      }
    },

    treatAsUTC(date) {
      const result = new Date(date);
      result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
      return result;
    },

    getMonthDiff(startDate, endDate) {
      const millisecondsPerDay = 24 * 60 * 60 * 1000;
      const days =
        (this.treatAsUTC(endDate) - this.treatAsUTC(startDate)) /
        millisecondsPerDay;
      const mountly = Math.ceil(days / 30);
      return mountly < 0 ? 0 : mountly;
    },

    handleSearch: _.debounce(function(value) {
      if (value.length > 2) {
        this.search = value;
        this.getServices();
      }
    }, 500),

    getServices() {
      api.get(`/service/`, { search: this.search }).then(({ data }) => {
        this.serviceOptions = data.results;
        this.$forceUpdate();
      });
    },

    getEdit() {
      this.get_service_scope({ id: this.serviceId }).then((data) => {
        this.form = data;
        this.emails = this.form.notifications.map(({ email }) => email);
        this.form.dependency_limit_perc = `${String(
          this.form.dependency_limit_perc
        )}%`;

        this.form.operational_base_localization_distance = `${this.form.operational_base_localization_distance}`;

        this.form.contract_value_mensal = `R$ ${String(
          this.form.contract_value_mensal
        )}`;
        // Aqui
        this.$nextTick(() => {
          this.maskDistance = maskDistance;
        });

        this.$forceUpdate();
      });
    },
    calculateInvoicing(contract_value_mensal = 0, dependency_limit_perc = 0) {
      this.form.monthly_invoicing_above = Number(
        ((contract_value_mensal / dependency_limit_perc) * 100).toFixed(2)
      );
    },
  },
  mounted() {
    this.homologationId = this.$route.params.homologation_id;
    this.serviceId = this.$route.params.service_id;
    if (this.serviceId) {
      this.getEdit();
    }
    this.getServices();
  },
  computed: {
    google: gmapApi,
    ...mapGetters(["user", "is_provider"]),
    state() {
      return this.dirty ? this.tags.length > 2 && this.tags.length < 9 : null;
    },
  },
  watch: {
    "form.contract_value_mensal": {
      handler(value) {
        const values = Number(
          value
            ?.replace("R$", "")
            .replace("R$ ", "")
            .replaceAll(".", "")
            .replace(",", ".")
            .replaceAll(" ", "")
        );
        const dependency_limit_perc = Number(
          this.form.dependency_limit_perc?.replace("%", "").replace(" %", "")
        );
        this.calculateInvoicing(values, dependency_limit_perc);
      },
      deep: true,
    },
    "form.dependency_limit_perc": {
      handler(value) {
        const percent = Number(value?.replace("%", "").replace(" %", ""));
        const contract_value_mensal = Number(
          this.form.contract_value_mensal
            ?.replace("R$", "")
            .replace("R$ ", "")
            .replaceAll(".", "")
            .replace(",", ".")
            .replaceAll(" ", "")
        );
        this.isMaxPercent = percent > 15;
        this.calculateInvoicing(contract_value_mensal, percent);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.list-emails {
  margin-bottom: 1em;
  overflow: auto;
}
.input-total-value {
  height: 40px;
  line-height: 2;
  font-size: 14px;
  border-radius: $radius;
  font-weight: 100;
  border-color: $border-color;
  background-color: $bg-2 !important;
  display: flex;
  align-items: center;
  @media (max-width: $break-md) {
    height: 60px;
  }
}
.distance-map {
  position: relative;
  overflow: hidden;
  .content {
    height: 350px;
    background: #ececec;
    .badge {
      margin: 1em;
      z-index: +1;
      position: absolute;
    }
    .radius {
      .pin {
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: 10px;
        height: 10px;
        background: $danger;
      }
      .stroke {
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: 10px;
        background: rgba($danger, 0.2);
        border: 2px solid $danger;
      }
    }
  }
}
</style>
